import { Button, Space } from "antd";
import { setselectedKey } from "../slices/mapView";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faEnvelope,
  faRssSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faFlickr,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import "../style/footer.css";
import KeepInTouch from "./KeepInTouch";

function Footer(props) {
  const dispatch = useDispatch();
  return (
    <>
      <KeepInTouch />
      <div
        className="footer-section"
        style={{
          background: "linear-gradient(180deg, #2a547c 0%, #224669 100%)",
        }}
      >
        <div className="footer-container container-fluid">
          <div className="align-items-center row">
            <div className="d-flex col-md-4">
              <Space direction="horizontal">
                <img src="/images/iwmi.png" alt="Logos" className="logos" />
                <img
                  src="https://www.cgiar.org/wp/wp-content/themes/cgiar/assets/images/logo@2x-0c19fb4ad3-0c19fb4ad3.png"
                  alt="Logos"
                  className="logos"
                />
                <img
                  src="https://cdn.freelogovectors.net/wp-content/uploads/2018/08/FAO_logo-590x600.png"
                  alt="Logos"
                  className="logos"
                  style={{ background: "white" }}
                />
                <img src="images/UKaid.jpg" alt="Logos" className="logos" />
                <img src="images/waporlogo.png" alt="Logos" className="logos" />
              </Space>
            </div>
            <div className="col-md-4 text-center">
              <ul className="list-unstyled d-flex flex-wrap justify-content-center social-icons">
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://www.facebook.com/IWMIonFB"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://www.flickr.com/photos/iwmi"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faFlickr}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://www.linkedin.com/company/international-water-management-institute-iwmi/"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href='mailto:iwmi@cgiar.org?subject="Contact IWMI via PakDMS"'
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://www.iwmi.cgiar.org/news/rss-feeds/"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faRssSquare}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://twitter.com/IWMI_"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faTwitter}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
                <li className="mx-2">
                  <Button
                    type="link"
                    href="https://www.youtube.com/user/iwmimedia/videos"
                    target="_blank"
                    icon={
                      <FontAwesomeIcon
                        icon={faYoutube}
                        color="white"
                        size="2x"
                      />
                    }
                  />
                </li>
              </ul>
              <Button
                href="/terms-conditions"
                target="_blank"
                type="link"
                className="footer-terms-style"
                onClick={() => dispatch(setselectedKey(null))}
              >
                Terms & Conditions
              </Button>
            </div>
            <div className="col-md-4 text-center">
              <Space direction="horizontal">
                <img
                  src="https://vectorseek.com/wp-content/uploads/2023/09/Government-Of-Pakistan-Logo-Vector.svg-.png"
                  alt="Logos"
                  className="logos"
                  style={{ background: "white" }}
                />
                <img
                  src="https://www.asiantelegraphqatar.com/wp-content/uploads/2018/08/Logo-Punjab-Govt-Pakistan-by-Wikipedia-1024x809.png"
                  alt="Logos"
                  className="logos"
                  style={{ background: "white" }}
                />
                <img
                  src="images/Pakistan_Met_Department.png"
                  alt="Logos"
                  className="logos"
                  style={{ background: "white" }}
                />
                <img
                  src="https://ndrmf.pk/wp-content/uploads/2022/10/logo-ndrm-main.png"
                  alt="Logos"
                  className="logos"
                />
              </Space>
            </div>

            {/* Footer Bottom Text */}
            <div className="row">
              <div className="col-12 text-center">
                <p className="copyright-text copy-text-left follow-text">
                  <FontAwesomeIcon icon={faCopyright} /> &nbsp;2024 Pakistan
                  Drought Monitoring System (PakDMS)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

import Axios from "axios";
import { useEffect, useState } from "react";
import { TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";

function EarthEngine(props) {
  const { loggedin } = useSelector((state) => state);
  const [data, setData] = useState(null);
  const {
    calcval,
    startmonth,
    endmonth,
    startyear,
    indicator,
    loading,
    error,
    res,
    calctype,
    selectedUnit,
    selectedDistrict,
    selectedTehsil,
    refresh,
    precipitation,
    months,
    opac,
    drawnFeature,
    boundarySelect,
    fileName,
  } = props;

  useEffect(() => {
    async function fetchMyAPI() {
      let unit, name;
      if (selectedTehsil) {
        unit = "tehsils";
        name = selectedTehsil;
      } else if (selectedDistrict) {
        unit = "districts";
        name = selectedDistrict;
      } else if (selectedUnit) {
        unit = "units";
        name = selectedUnit;
      }

      // let vals = indicator.split("/");

      await Axios.post("../python/getdata", {
        params: {
          startmonth: startmonth,
          endmonth: endmonth,
          startyear: startyear,
          ...(drawnFeature ? { drawnFeature: JSON.stringify(drawnFeature[0].geometry) } : {}),
          unit: unit,
          name: name,
          ...(fileName ? { fileName: fileName } : {}),
          boundarySelect: boundarySelect,
          ...(indicator === "DrySpell" ? { precipitation: precipitation } : {}),
          ...(indicator === "DrySpell" ? {} : { aggr: calcval }),
          ...(indicator === "SPI_CHIRPS" || indicator === "SPI_ERA5L"
            ? { months: months }
            : {}),
          indice: indicator === "CWDI" ? "CWD" : indicator,
          calctype: calctype,
        },
      }).then(function (response) {
        if (response.data.data.indexOf("Error") !== -1) {
          error(response.data.data);
        } else {
          const { mapid } = JSON.parse(response.data.data);
          res(JSON.parse(response.data.data));
          setData(mapid);
          loading(false);
        }
      });
    }

    if (loggedin && indicator && refresh) {
      fetchMyAPI();
    }
  }, [calcval, startmonth, endmonth, startyear, indicator, loggedin]);

  if (data) {
    return <TileLayer url={data} opacity={opac} />;
  } else {
    return "";
  }
}
export default EarthEngine;
